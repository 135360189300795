<template>
    <div>
        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <h2 class="page-title">Ibu Hamil</h2>
                </div>
            </div>
        </div>
        <!-- end page title end breadcrumb -->
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-lg-3 col-sm-12">
                        <div class="row">
                            <div class="col-xl-12 col-sm-12">
                                <div class="card overflow-hidden">
                                    <div class="card-heading bg-light">
                                        <input type="checkbox" id="filWilayah" v-model="isActive.wilayah"
                                            data-switch="default" @change="changeActive('wilayah', $event)"
                                            :checked="isActive.wilayah" />
                                        <label for="filWilayah" data-on-label="" data-off-label=""></label>
                                        <h3 class="card-title text-uppercase float-right">Filter Wilayah</h3>
                                        <p class="card-sub-title font-13 text-muted">Filter Ibu Hamil Per Wilayah
                                        </p>
                                    </div>
                                    <div class="card-body">
                                        <p class="m-t-0 header-title"><b>Kecamatan</b></p>
                                        <Select2 :disabled="!isActive.wilayah" v-model="filter.kecKode"
                                            :options="data.kecamatan" @select="selectKecamatan($event)" />
                                        <p class="header-title mt-2"><b>Desa</b></p>
                                        <Select2 :disabled="!isActive.wilayah" v-model="filter.kodeKab" :options="data.desa"
                                            @select="selectDesa($event)" />
                                        <p class="header-title mt-2"><b>Posyandu</b></p>
                                        <Select2 :disabled="!isActive.wilayah" v-model="filter.kdPosyandu"
                                            :options="data.posyandu" @select="selectPosyandu($event)" />
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-sm-12">
                                <div class="card overflow-hidden">
                                    <div class="card-heading bg-light">
                                        <input type="checkbox" v-model="isActive.puskesmas" id="filPkm"
                                            data-switch="default" @change="changeActive('puskesmas', $event)"
                                            :checked="isActive.puskesmas" />
                                        <label for="filPkm" data-on-label="" data-off-label=""></label>
                                        <h3 class="card-title text-uppercase float-right">Filter Puskesmas</h3>
                                        <p class="card-sub-title font-13 text-muted">Filter Ibu Hamil Per Puskesmas
                                        </p>
                                    </div>
                                    <div class="card-body">
                                        <p class="header-title mt-2"><b>Puskesmas : {{ filter.pkmKode }}</b></p>
                                        <Select2 :disabled="!isActive.puskesmas" v-model="filter.pkmKode"
                                            :options="data.puskesmas" />
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!--end row -->
                        <div class="row">
                            <div class="col-xl-12 col-sm-12">
                                <div class="card overflow-hidden">
                                    <div class="card-heading bg-light">
                                        <h3 class="card-title text-uppercase">Filter Variable</h3>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label class="col-md-3 control-label">Jenis</label>
                                            <div class="col-md-9">
                                                <Select2 id="filJenis" v-model="filter.filJenis" :options="data.jenis" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-3 control-label">Gakin</label>
                                            <div class="col-md-9">
                                                <Select2 id="filGakin" :placeholder="filter.filGakin"
                                                    v-model="filter.filGakin" :options="data.gakin" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-3 control-label">Risti</label>
                                            <div class="col-md-9">
                                                <Select2 id="filRisti" :placeholder="filter.filRisti"
                                                    v-model="filter.filRisti" :options="data.risti" />
                                            </div>
                                        </div>
                                        <div class="form-group ml-1">
                                            <div class="checkbox checkbox-custom">
                                                <input v-model="isActive.periode" id="periode" type="checkbox">
                                                <label for="periode">
                                                    Periode Registrasi diaktifkan
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group ml-1">
                                            <div class="checkbox checkbox-custom">
                                                <input v-model="filter.hpl" id="hpl" type="checkbox">
                                                <label for="hpl">
                                                    HPL lewat tanggal
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group ml-1">
                                            <div class="checkbox checkbox-custom">
                                                <input v-model="filter.infoTbl" id="infoTbl" type="checkbox">
                                                <label for="infoTbl">
                                                    Informasi Nama Tabel
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!--end row -->
                        <div class="row mb-2">
                            <div class="col-xl-12 col-sm-12">
                                <label class="control-label">Ubah Periode</label>
                                <Datepicker :disabled="!isActive.periode" :format="format" locale="id" v-model="date"
                                    cancelText="Batal" selectText="Pilih" range />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-sm-12">
                                <button @click="showData()"
                                    class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                    :disabled="is_loading"><span
                                        :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                        aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Tampilkan Data"
                                        }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-sm-12">
                        <div class="card overflow-hidden">
                            <div class="card-heading bg-light d-flex justify-content-between align-items-center">
                                <h3 class="card-title text-uppercase">Data Ibu Hamil</h3>
                                <router-link :to="{name:'bumilRegister'}" class="btn btn-custom waves-effect waves-light">Register Ibu Hamil</router-link>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table id="tb-bumil" class="table table-striped table-bordered nowrap"
                                        style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                        <thead>
                                            <tr>
                                                <th>No Index</th>
                                                <th>Tgl Reg</th>
                                                <th>Nama</th>
                                                <th>Tgl Lahir</th>
                                                <th>BB Awal</th>
                                                <th>Umur</th>
                                                <th>Umur Hamil</th>
                                                <th>Umur Kehamilan Sekarang</th>
                                                <th>Suami</th>
                                                <th>No. Telp</th>
                                                <th>No. Telp Suami</th>
                                                <th>HTP</th>
                                                <th>Desa</th>
                                                <th>Puskesmas</th>
                                                <th>Kecamatan</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import Select2 from 'vue3-select2-component';
import { useToast } from "vue-toastification";
import { ref, onMounted } from 'vue';
import { useDasar } from '@/api/dasar'

export default {
    name: 'Home',
    components: {
        Select2
    },
    setup() {
        const date = ref();
        const toast = useToast();

        onMounted(() => {
            const startDate = new Date();
            const endDate = new Date(new Date().setDate(startDate.getDate()));
            date.value = [startDate, endDate];
        })

        return {
            date,
            toast,
        }
    },
    data() {
        return {
            bumil: [],
            table: null,
            is_loading: false,
            isActive: {
                puskesmas: false,
                wilayah: true,
                periode: false
            },
            filter: {
                pkmKode: '',
                kecKode: '',
                kodeKab: 'all',
                kdPosyandu: 'all',
                filJenis: 'all',
                filGakin: '000',
                filRisti: '000',
                hpl: false,
                infoTbl: false,
                dateStart: '',
                dateEnd: ''
            },
            data: {
                puskesmas: [],
                kecamatan: [],
                desa: [],
                posyandu: [{ id: 'all', text: '- Semua Posyandu -' }],
                jenis: [{ id: 'all', text: '- Semua Jenis Bumil -' }],
                gakin: [
                    { id: '000', text: 'Semua Bumil' },
                    { id: '0', text: 'Non Gakin' },
                    { id: '1', text: 'Gakin' }
                ],
                risti: [
                    { id: '000', text: 'Semua Bumil' },
                    { id: '0', text: 'Non Risti' },
                    { id: '1', text: 'Risti' }
                ]
            }
        };
    },
    methods: {
        async getPuskesmas() {
            await axios.get(`dasar/puskesmas`)
                .then(response => {
                    this.data.puskesmas = response.data.content.puskesmas.map(pkm => (
                        { id: pkm.Kode, text: pkm.Puskesmas.toUpperCase() }
                    ));
                    this.filter.pkmKode = this.data.puskesmas[0].id;
                })
                .catch(e => {
                    this.$toast.error(e.response.data.errors.message.description);
                })
        },
        async getKecamatan() {
            await axios.get(`dasar/kecamatan`)
                .then(response => {
                    this.data.kecamatan = response.data.content.kecamatan.map(kec => (
                        { id: kec.Kode_Kecamatan, text: kec.Kecamatan.toUpperCase() }
                    ));
                    this.filter.kecKode = this.data.kecamatan[0].id;
                    this.getDesa();
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        async getDesa() {
            await axios.get(`dasar/desa/` + this.filter.kecKode)
                .then(response => {
                    var desa = response.data.content.desa.map(item => (
                        { id: item.KodeKab, text: item.Desa.toUpperCase() }
                    ));

                    var defDesa = [{ id: 'all', text: '- Semua -' }];
                    this.data.desa = [...defDesa, ...desa];
                    this.filter.kodeKab = this.data.desa[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getPosyandu() {
            const apiDataDasar = useDasar()
            let posyandu    = await apiDataDasar.getPosyandu(this.filter.kodeKab)
            let defPosyandu = [{ id: 'all', text: '- Semua Posyandu -' }];

            this.data.posyandu = [...defPosyandu, ...posyandu];
            this.filter.kdPosyandu = this.data.posyandu[0].id;
        },
        async getJenisGakin() {
            const apiDataDasar = useDasar()
            let jenis = await apiDataDasar.getJenisGakin()
            let defJenis = [{ id: 'all', text: '- Semua Jenis Bumil -' }];

            this.data.jenis = [...defJenis, ...jenis];
            this.filter.filJenis = this.data.jenis[0].id;
        },
        selectKecamatan(event) {
            if (event.id !== '') {
                this.getDesa();
            }
        },
        selectDesa(event) {
            if (event.id !== '') {
                this.getPosyandu();
            }
        },
        async showData() {
            this.is_loading = true;
            await axios({
                method: 'POST',
                url: 'bumil',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    'kdPkm': (this.isActive.puskesmas) ? this.filter.pkmKode : '',
                    'kdKecamatan': (this.isActive.wilayah) ? this.filter.kecKode : '',
                    'kdDesa': (this.isActive.wilayah && this.filter.kodeKab !== 'all') ? this.filter.kodeKab : '',
                    'kdPosyandu': (this.isActive.wilayah && this.filter.kdPosyandu !== 'all') ? this.filter.kdPosyandu : '',
                    'jnGakin': (this.filter.filJenis !== 'all') ? this.filter.filJenis : '',
                    'gakin': (this.filter.filGakin !== '000') ? this.filter.filGakin : '',
                    'risti': (this.filter.filRisti !== '000') ? this.filter.filRisti : '',
                    'htp': (this.filter.hpl) ? true : '',
                    'periodeRegStart': (this.isActive.periode) ? `${this.date[0].getFullYear()}-${("0" + (this.date[0].getMonth() + 1)).slice(-2)}-${("0" + this.date[0].getDate()).slice(-2)}` : ``,
                    'periodeRegEnd': (this.isActive.periode) ? `${this.date[1].getFullYear()}-${("0" + (this.date[1].getMonth() + 1)).slice(-2)}-${("0" + this.date[1].getDate()).slice(-2)}` : ``
                }
            })
                .then(response => {
                    this.is_loading = false;
                    this.bumil = response.data.content.bumil;
                    $('#tb-bumil').DataTable().destroy();
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        changeActive(current, event) {
            if (current == 'wilayah') {
                this.isActive.puskesmas = false;
                this.isActive.wilayah = true;
            } else {
                this.isActive.wilayah = false;
                this.isActive.puskesmas = true;
            }
        },
        dateDiffInDays(a, b) {
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;
            const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        }
    },
    watch: {
        bumil() {
            var self = this;
            $('#tb-bumil').DataTable({
                processing: true,
                destroy: true,
                pageLength: 15,
                scrollX: true,
                orders: [],
                data: this.bumil,
                columns: [
                    { data: "No_Index_Bumil_Dinas" },
                    { data: "Tgl_Register" },
                    { data: "Nama" },
                    { data: "Tgl_Lahir" },
                    { data: "BBAwal" },
                    { data: "Umur" },
                    { data: "Umur_Kehamilan" },
                    { data: "umur_kehamilan_sekarang" },
                    { data: "Suami" },
                    { data: "No_Telepon_Istri" },
                    { data: "No_Telepon_Suami" },
                    { data: "HTP" },
                    { data: "Desa" },
                    { data: "puskesmas" },
                    { data: "Kecamatan" },
                ],
                columnDefs: [
                    {targets: 0, className:"no_index"},
                    {targets: 2, className:"nama"},
                    {targets: 11, className:"clHtp"},
                ],
                rowCallback: function( row, data ) {
                    const htp_date     = new Date(data.HTP);
                    const current_date = new Date();
                    const difference   = self.dateDiffInDays(htp_date, current_date);
                    
                    if ( self.filter.filGakin === "1" ) {
                        $('td.no_index', row).css( { background : 'yellow', color : 'red' } );
                    }

                    if (data.statusRisti === 'Ya') {
                        $('td.nama', row).css( { background : 'yellow', color : 'red', 'border-bottom':'#fff' } );
                    }

                    if (difference >= 14) {
                        $('td.clHtp', row).css( { background : '#00cc00', color : '#ffff00', 'border-bottom':'#fff' } );
                    }

                    if (difference < 14) {
                        $('td.clHtp', row).css( { background : '#ff0000', color : '#ffff00' } );
                    }
                    
                    if (htp_date.setHours(0,0,0,0) == current_date.setHours(0,0,0,0)) {
                        $('td.clHtp', row).css( { background : '#ff3399', color : '#ffff00' } );
                    }
                    
                    if (difference < 14) {
                        $('td.clHtp', row).css( { background : '#000000', color : 'red', borderBottom:'#fff' } );
                    }
                    
                    if (difference >= 14) {
                        $('td.clHtp', row).css( { background : '#000000', color : '#ff0000', borderBottom:'#fff' } );
                    }
                },
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Menampilkan halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
            });
        },
        $route(to, from) {
            $('#tb-bumil').DataTable().clear().draw();
            $('#tb-bumil').DataTable().destroy();
        }
    },
    created() {
        this.getPuskesmas();
        this.getKecamatan();
        this.getJenisGakin();
        this.$nextTick(function () {
            $('#tb-bumil').DataTable();
        })
    }
}
</script>

<style>

</style>